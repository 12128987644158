@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

@import "theme.css";
@import "components/questions.css";
@import "components/result-content.css";

/* ------ Add your CSS below this line (or @import your other CSS files). ------- */

@font-face
{
    font-family: "font-shapes";
    src: url("../font/font-shapes.otf") format("opentype");
}

body
{
    @apply bg-gradient-to-br from-theme-600 to-theme-900;
}


.flag
{
    font-family: 'font-shapes', Arial, sans-serif;
    font-weight: normal;
    font-style: normal;
}

.choices .choice-box
{
    @apply border transition-all cursor-pointer border-white border-opacity-0;
    @apply hover:border-theme-100 hover:scale-110;
}

.choices .choice .choice-label
{
    @apply relative;
    @apply before:transition-all before:block before:absolute before:w-0 before:left-1/2 before:h-full before:bg-theme-300 before:rounded-md;
}

.choices .choice:hover .choice-label
{
    @apply before:w-full before:left-0;
}

.speech-box
{
    margin: 50px auto;
    border-radius: 15px;
    color: #fff;
    text-align: center;
    font-weight: 900;
    font-family: arial;
    position: relative;
    z-index: 1;

    @apply bg-theme-400 bg-gradient-to-br from-theme-300 to-theme-700 text-xl py-6 px-6 shadow-2xl;
}

.speech-box:before
{
    content: "";
    width: 0px;
    height: 0px;
    position: absolute;
    z-index: 0;
    border-left: 15px solid transparent;
    border-right: 15px solid #4150D2;
    border-top: 15px solid #4150D2;
    border-bottom: 15px solid transparent;
    left: -16px;
    top: 0px;
}

.text-shadow-lg
{
    @apply xs:text-4xl 2xs:text-2xl text-lg;
    text-shadow: 0px 4px 0px #000, 0px 0px 5px #000;
}

.shield-mask-1
{
    -webkit-mask-image: url('../img/shield-1.svg');
    mask-image: url('../img/shield-1.svg');
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;  
}

.shield-mask-bg-1
{
    -webkit-mask-image: url('../img/shield-model-1.svg');
    mask-image: url('../img/shield-model-1.svg');
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;  
}

.shield-shadow
{
    @apply absolute w-full h-[92%] left-0 bottom-2;

    box-shadow: 0px -5px 30px 10px rgb(255, 255, 255, 0.4), inset 0px -5px 30px 10px rgb(255, 255, 255, 0.4);
    border-radius: 50% 50% 50% 50% / 28% 28% 68% 68%;
    
}

.shield-logo-shadow
{
    @apply absolute 2xs:w-3/4 w-1/2 h-1/2 left-1/2 2xs:ml-[-37%] ml-[-25%] bottom-1/4 rounded-full;

    background-color: rgba(0,0,0, 0.8);
    box-shadow: 0px 0px 30px 20px rgb(0,0,0, 0.8);
    border-radius: 50% 50% 50% 50% / 50% 50% 50% 50%;
}