/* --- Theme Red --- */

body.theme-red
{
    @apply bg-persian-800 from-persian-600 to-persian-900 bg-gradient-to-br;
}

.theme-red .choice-label .text
{
    @apply bg-persian-300;
}

.theme-red .choice-text span
{
    @apply text-persian-100;
}

.theme-red .result-content h2 
{
    @apply text-persian-600;
}

.theme-red .result-content .cta
{
    @apply bg-persian-300;
}

.theme-red .choices .choice-box
{
    @apply hover:border-persian-100;
}

.theme-red .choices .choice .choice-label
{
    @apply before:bg-persian-300;
}

.theme-red .speech-box
{
    @apply bg-persian-400 from-persian-300 to-persian-700;
}

.theme-red .question-label
{
    @apply bg-persian-300;
}

.theme-red .result-image-bg
{
    @apply bg-persian-200;
}

.theme-red .loading-cloak
{
    @apply bg-persian-900;
}

/* --- Theme Green --- */

body.theme-green
{
    @apply bg-meadow-800 from-meadow-600 to-meadow-900 bg-gradient-to-br;
}

.theme-green .choice-label .text
{
    @apply bg-meadow-300;
}

.theme-green .choice-text span
{
    @apply text-meadow-100;
}

.theme-green .result-content h2 
{
    @apply text-meadow-600;
}

.theme-green .result-content .cta
{
    @apply bg-meadow-300;
}

.theme-green .choices .choice-box
{
    @apply hover:border-meadow-100;
}

.theme-green .choices .choice .choice-label
{
    @apply before:bg-meadow-300;
}

.theme-green .speech-box
{
    @apply bg-meadow-400 from-meadow-300 to-meadow-700;
}

.theme-green .question-label
{
    @apply bg-meadow-300;
}

.theme-green .result-image-bg
{
    @apply bg-meadow-200;
}

.theme-green .loading-cloak
{
    @apply bg-meadow-900;
}