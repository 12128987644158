.choices
{
    @apply flex xs:flex-row flex-col border-opacity-50 xs:mt-10 2xs:mt-6 mt-4 xs:gap-6 2xs:gap-4 gap-3;
}

.choices .choice
{
    @apply flex-1;
}

.choice-box
{
    @apply xs:block flex flex-row-reverse gap-2 shadow-2xl text-black text-center mx-auto bg-black bg-opacity-30 backdrop-blur-lg rounded-lg overflow-hidden xs:px-6 2xs:py-4 py-2.5 2xs:px-3 px-2;
}

.choice-label
{
    @apply block flex-shrink;
}

.choice-label .text
{
    @apply relative rounded-full text-center h-12 w-12 bg-theme-300 text-white mx-auto xs:text-2xl text-xl xs:pt-[7px] leading-[48px];
}

.choice-text
{
    @apply bg-white bg-opacity-100 rounded-md xs:mb-3 mb-0 xs:px-4 xs:py-6 p-2 flex-1 place-content-center;
}

.choice-text
{
    @apply xs:block flex h-full self-center;
}

.choice-text em
{
    @apply xs:block flex-shrink self-center xs:text-4xl text-xl not-italic;
}

.choice-text span
{
    @apply xs:block flex-1 self-center xs:text-2xl text-xl text-theme-100 xs:pt-1;
}

.question-text-container
{
    @apply flex xs:text-2xl text-xl border-opacity-50 shadow-2xl rounded-lg overflow-hidden;
}

.question-label
{
    @apply xs:flex-shrink hidden text-white py-4 px-4;
}