.result-content > * 
{
    @apply mb-3;
}

.result-content 
{
    @apply text-center;
}

.result-content ol 
{
    @apply list-decimal opacity-80;
}

.result-content h2 
{
    @apply xs:text-lg mb-5 text-theme-600 font-bold;
}

.result-content h3 
{
    @apply mt-5;
}

.result-content .cta
{
    @apply p-2.5 px-6 rounded-md shadow-md inline-block bg-theme-300 text-white mt-3 relative;
    box-shadow: 0px 0px 10px 2px rgb(0, 0, 0, 0.4);
}

.result-content .cta 
{
    border: none;
    color: #fff;
    overflow: hidden;
}

.result-content .cta:hover 
{
    text-decoration: none;
    color: #fff;
}

.result-content .cta:before 
{
    position: absolute;
    content: '';
    display: inline-block;
    top: -180px;
    left: 0;
    width: 30px;
    height: 100%;
    background-color: #fff;
    animation: shiny-btn 3s ease-in-out infinite;
}

.result-content .cta:hover 
{
    opacity: .7;
}

.result-content .cta:active 
{
    box-shadow: 4px 4px 6px 0 rgba(255, 255, 255, .3),
        -4px -4px 6px 0 rgba(116, 125, 136, .2),
        inset -4px -4px 6px 0 rgba(255, 255, 255, .2),
        inset 4px 4px 6px 0 rgba(0, 0, 0, .2);
}


@-webkit-keyframes shiny-btn 
{
    0% 
    {
        -webkit-transform: scale(0) rotate(45deg);
        opacity: 0;
    }

    80% 
    {
        -webkit-transform: scale(0) rotate(45deg);
        opacity: 0.5;
    }

    81% 
    {
        -webkit-transform: scale(4) rotate(45deg);
        opacity: 1;
    }

    100% 
    {
        -webkit-transform: scale(50) rotate(45deg);
        opacity: 0;
    }
}